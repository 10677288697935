.revenueNumber {
  animation: fadein 1s ease-in;
  font-size: 1.5rem;
  margin-top: 0;
  margin-bottom: 0;
  display: inline-block;
  padding: 0.5rem;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  border: 0;
}

.revenueNumber-label {
  font-size: 0.8rem;
  margin: 0;
  color: #1b1b1b;
  background-color: #fcfcfc;
  border-radius: 0.25rem;
  padding: 0.15rem;
}

.revenueNumber-number {
  margin: 0.5rem 0;
  color: #1b1b1b;
}

.revenue-total {
  background-color: #fcfcfc;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  display: inline-block;
  font-size: 1.25rem;
  padding: 0.5rem;
}
