.button {
  padding: 0.5rem;
  border: 0;
  border-bottom: 4px #eee solid;
  margin-right: 1rem;
  cursor: pointer;
  background-color: #eee;
}

.button:hover {
  border-bottom: 4px #868686 solid;
}

.button.button-active {
  border-bottom: 4px #569afc solid;
}
